import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(common)": [4,[2]],
		"/classes": [12],
		"/classes/new": [~15],
		"/classes/[classId]": [~13],
		"/classes/[classId]/devices": [~14],
		"/(common)/content/[...directory]": [~5,[2]],
		"/(common)/history": [~6,[2,3]],
		"/(common)/search": [~7,[2]],
		"/(common)/settings/account": [~8,[2]],
		"/(common)/settings/privacy": [~9,[2]],
		"/(common)/settings/security": [~10,[2]],
		"/simulator": [16],
		"/(common)/tasks/[tasksetId]": [11,[2]],
		"/waitlist": [17]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';